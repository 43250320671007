export default [
  {
    path: '/pengguna/list',
    name: 'pengguna-list',
    component: () => import('@/views/user/list/List.vue'),
  },
  {
    path: '/pengguna/edit/:id',
    name: 'pengguna-edit',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Ubah Data Pengguna',
      breadcrumb: [
        {
          text: 'Pengguna',
          to: '/pengguna/list',
        },
        {
          text: 'Ubah Data Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
  },
  {
    path: '/laporan/view/:laporan_id',
    name: 'laporan-readonly',
    component: () => import('@/views/laporan/view/View.vue'),
  },
  {
    path: '/tim',
    name: 'tim',
    component: () => import('@/views/tim/list/List.vue'),
  },
  {
    path: '/tim/:id',
    name: 'tim-edit',
    component: () => import('@/views/tim/edit/Edit.vue'),
  },
  {
    path: '/tpl-monitoring',
    name: 'tpl-monitoring',
    component: () => import('@/views/tpl-monitoring/list/List.vue'),
  },
  {
    path: '/tpl-monitoring/:laporan_id/:mode',
    name: 'tpl-monitoring-edit',
    component: () => import('@/views/tpl-monitoring/wizard/FormWizard.vue'),
  },
  {
    path: '/tpl-analisis-laporan',
    name: 'tpl-analisis-laporan',
    component: () => import('@/views/tpl-analisis-laporan/list/List.vue'),
  },
  {
    path: '/tpl-analisis-laporan/:laporan_id/:mode',
    name: 'tpl-analisis-laporan-edit',
    component: () => import('@/views/tpl-analisis-laporan/wizard/FormWizard.vue'),
  },
  {
    path: '/tpl-pemeriksaan-pendahuluan',
    name: 'tpl-pemeriksaan-pendahuluan',
    component: () => import('@/views/tpl-pemeriksaan-pendahuluan/list/List.vue'),
  },
  {
    path: '/tpl-pemeriksaan-pendahuluan/:laporan_id/:mode',
    name: 'tpl-pemeriksaan-pendahuluan-edit',
    component: () => import('@/views/tpl-pemeriksaan-pendahuluan/wizard/FormWizard.vue'),
  },
  {
    path: '/tpl-pemeriksaan-lanjutan',
    name: 'tpl-pemeriksaan-lanjutan',
    component: () => import('@/views/tpl-pemeriksaan-lanjutan/list/List.vue'),
  },
  {
    path: '/tpl-pemeriksaan-lanjutan/:laporan_id/:mode',
    name: 'tpl-pemeriksaan-lanjutan-edit',
    component: () => import('@/views/tpl-pemeriksaan-lanjutan/wizard/FormWizard.vue'),
  },
  {
    path: '/tpl-jadwal-pemeriksaan',
    name: 'tpl-jadwal-pemeriksaan',
    component: () => import('@/views/tpl-jadwal-pemeriksaan/list/List.vue'),
  },
  {
    path: '/tpl-jadwal-pemeriksaan/:laporan_id/:mode',
    name: 'tpl-jadwal-pemeriksaan-edit',
    component: () => import('@/views/tpl-jadwal-pemeriksaan/wizard/FormWizard.vue'),
  },
  {
    path: '/peran',
    name: 'peran',
    component: () => import('@/views/roles/list/List.vue'),
  },
  {
    path: '/peran/:id',
    name: 'peran-edit',
    component: () => import('@/views/roles/edit/Edit.vue'),
  },
  {
    path: '/pengguna/rules/list',
    name: 'pengguna-rules-list',
    component: () => import('@/views/rules/list/List.vue'),
  },
  {
    path: '/rules/edit/:id',
    name: 'rules-edit',
    component: () => import('@/views/rules/edit/Edit.vue'),
  },
  {
    path: '/dashboard/monitoring',
    name: 'dashboard-monitoring',
    component: () => import('@/views/laporan/list/Laporan.vue'),
  },
  {
    path: '/dokumen/arsip',
    name: 'dokumen-arsip',
    component: () => import('@/views/laporan/list/arsip.vue'),
  },
  {
    path: '/penerimaan',
    name: 'penerimaan',
    component: () => import('@/views/laporan/list/Penerimaan.vue'),
  },
  {
    path: '/penerimaan/online',
    name: 'penerimaan-online',
    component: () => import('@/views/laporan/list/PenerimaanOnline.vue'),
  },
  {
    path: '/penerimaan/pos',
    name: 'penerimaan-pos',
    component: () => import('@/views/laporan/list/PenerimaanPos.vue'),
  },
  {
    path: '/penerimaan/kantor-penghubung',
    name: 'penerimaan-kantor-penghubung',
    component: () => import('@/views/laporan/list/PenerimaanKantorPenghubung.vue'),
  },
  {
    path: '/penerimaan/create',
    name: 'penerimaan-create',
    component: () => import('@/views/laporan/wizard/FormWizardCreate.vue'),
  },
  {
    path: '/penerimaan/:laporan_id',
    name: 'penerimaan-edit',
    component: () => import('@/views/laporan/wizard/FormWizard.vue'),
  },
  {
    path: '/pendahuluan/penerimaan/:laporan_id/:readonly/:penerimaan',
    name: 'penerimaan-save',
    component: () => import('@/views/laporan/wizard/FormWizardPenerimaan.vue'),
  },
  {
    path: '/laporan/:laporan_id',
    name: 'laporan-edit',
    component: () => import('@/views/laporan/wizard/FormWizard.vue'),
  },
  {
    path: '/distribusi-berkas',
    name: 'tim-distribusi-berkas',
    component: () => import('@/views/laporan/distribusi-berkas/list/List.vue'),
  },
  {
    path: '/status-investigasi-advokasi',
    name: 'status-investigasi-advokasi',
    component: () => import('@/views/status-investigasi-advokasi/list/List.vue'),
  },
  {
    path: '/laporan-register/detail/:laporan_id',
    name: 'detail-laporan-register',
    component: () => import('@/views/laporan-register/detail-laporan-register/DetailLaporanRegister.vue'),
  },
  {
    path: '/laporan-register',
    name: 'laporan-register',
    component: () => import('@/views/laporan-register/list/LaporanRegister.vue'),
  },
  {
    path: '/investigasi-advokasi',
    name: 'investigasi-advokasi',
    component: () => import('@/views/investigasi-advokasi/list/InvestigasiAdvokasi.vue'),
  },
  {
    path: '/investigasi-advokasi/detail/:laporan_id',
    name: 'detail-investigasi-advokasi',
    component: () => import('@/views/investigasi-advokasi/detail-investigasi-advokasi/DetailInvestigasiAdvokasi.vue'),
  },
  {
    path: '/verifikasi',
    name: 'verifikasi',
    component: () => import('@/views/laporan/list/Verifikasi.vue'),
  },
  {
    path: '/verifikasi/pending',
    name: 'verifikasi-pending',
    component: () => import('@/views/laporan/list/VerifikasiPending.vue'),
  },
  {
    path: '/verifikasi/detail/:laporan_id',
    name: 'detail-verifikasi',
    component: () => import('@/views/laporan/detail-verifikasi/DetailVerifikasi.vue'),
  },
  // {
  //   path: '/dokumen/arsip',
  //   name: 'dokumen-arsip',
  //   component: () => import('@/views/laporan/list/arsip.vue'),
  // },
  // {
  //   path: '/arsip/detail/:laporan_id',
  //   name: 'detail-arsip',
  //   component: () => import('@/views/laporan/detail-arsip/DetailArsip.vue'),
  // },
  {
    path: '/negara',
    name: 'negara',
    component: () => import('@/views/negara/list/List.vue'),
  },
  {
    path: '/negara/:id',
    name: 'negara-edit',
    component: () => import('@/views/negara/edit/Edit.vue'),
  },
  {
    path: '/provinsi',
    name: 'provinsi',
    component: () => import('@/views/provinsi/list/List.vue'),
  },
  {
    path: '/provinsi/:id',
    name: 'provinsi-edit',
    component: () => import('@/views/provinsi/edit/Edit.vue'),
  },
  {
    path: '/kota',
    name: 'kota',
    component: () => import('@/views/kota/list/List.vue'),
  },
  {
    path: '/kota/:id',
    name: 'kota-edit',
    component: () => import('@/views/kota/edit/Edit.vue'),
  },
  {
    path: '/pelapor',
    name: 'pelapor',
    component: () => import('@/views/pelapor/list/List.vue'),
  },
  {
    path: '/status-pelaporan',
    name: 'status-pelaporan',
    component: () => import('@/views/status-pelaporan/list/List.vue'),
  },
  {
    path: '/status-pelaporan/:id',
    name: 'status-pelaporan-edit',
    component: () => import('@/views/status-pelaporan/edit/Edit.vue'),
  },
  {
    path: '/dugaan-pelanggaran',
    name: 'dugaan-pelanggaran',
    component: () => import('@/views/dugaan-pelanggaran/list/List.vue'),
  },
  {
    path: '/dugaan-pelanggaran/:id',
    name: 'dugaan-pelanggaran-edit',
    component: () => import('@/views/dugaan-pelanggaran/edit/Edit.vue'),
  },
  {
    path: '/jenis-perkara',
    name: 'jenis-perkara',
    component: () => import('@/views/jenis-perkara/list/List.vue'),
  },
  {
    path: '/jenis-perkara/:id',
    name: 'jenis-perkara-edit',
    component: () => import('@/views/jenis-perkara/edit/Edit.vue'),
  },
  {
    path: '/jenis-penyampaian',
    name: 'jenis-penyampaian',
    component: () => import('@/views/jenis-penyampaian/list/List.vue'),
  },
  {
    path: '/jenis-penyampaian/:id',
    name: 'jenis-penyampaian-edit',
    component: () => import('@/views/jenis-penyampaian/edit/Edit.vue'),
  },
  {
    path: '/jenis-pekerjaan',
    name: 'jenis-pekerjaan',
    component: () => import('@/views/jenis-pekerjaan/list/List.vue'),
  },
  {
    path: '/jenis-pekerjaan/:id',
    name: 'jenis-pekerjaan-edit',
    component: () => import('@/views/jenis-pekerjaan/edit/Edit.vue'),
  },
  {
    path: '/kelengkapan-data',
    name: 'kelengkapan-data',
    component: () => import('@/views/kelengkapan-data/list/List.vue'),
  },
  {
    path: '/kelengkapan-data/:id',
    name: 'kelengkapan-data-edit',
    component: () => import('@/views/kelengkapan-data/edit/Edit.vue'),
  },
  {
    path: '/kuasa-pelapor',
    name: 'kuasa-pelapor',
    component: () => import('@/views/kuasa-pelapor/list/List.vue'),
  },
  {
    path: '/kuasa-pelapor/:id',
    name: 'kuasa-pelapor-edit',
    component: () => import('@/views/kuasa-pelapor/edit/Edit.vue'),
  },
  {
    path: '/terlapor',
    name: 'terlapor',
    component: () => import('@/views/terlapor/list/List.vue'),
  },
  {
    path: '/terlapor/:id',
    name: 'terlapor-edit',
    component: () => import('@/views/terlapor/edit/Edit.vue'),
  },
  {
    path: '/kantor-penghubung',
    name: 'kantor-penghubung',
    component: () => import('@/views/kantor-penghubung/list/List.vue'),
  },
  {
    path: '/kantor-penghubung/:id',
    name: 'kantor-penghubung-edit',
    component: () => import('@/views/kantor-penghubung/edit/Edit.vue'),
  },
  {
    path: '/lingkungan-peradilan',
    name: 'lingkungan-peradilan',
    component: () => import('@/views/lingkungan-peradilan/list/List.vue'),
  },
  {
    path: '/lingkungan-peradilan/:id',
    name: 'lingkungan-peradilan-edit',
    component: () => import('@/views/lingkungan-peradilan/edit/Edit.vue'),
  },
  {
    path: '/kategori-faq/list',
    name: 'kategori-faq',
    component: () => import('@/views/kategori-faq/list/List.vue'),
  },
  {
    path: '/kategori-faq/edit/:id',
    name: 'kategori-faq-edit',
    component: () => import('@/views/kategori-faq/edit/Edit.vue'),
  },
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
    path: '/agama',
    name: 'agama',
    component: () => import('@/views/agama/list/List.vue'),
  },
  {
    path: '/agama/:id',
    name: 'agama-edit',
    component: () => import('@/views/agama/edit/Edit.vue'),
  },
  {
    path: '/status-berkas',
    name: 'status-berkas',
    component: () => import('@/views/status-berkas/list/List.vue'),
  },
  {
    path: '/status-berkas/:id',
    name: 'status-berkas-edit',
    component: () => import('@/views/status-berkas/edit/Edit.vue'),
  },
  {
    path: '/template',
    name: 'template',
    component: () => import('@/views/template/list/List.vue'),
  },
  {
    path: '/template/:id',
    name: 'template-edit',
    component: () => import('@/views/template/edit/Edit.vue'),
  },
  {
    // path: '/apps/chat',
    // name: 'apps-chat',
    // component: () => import('@/views/chat/Chat.vue'),
    // meta: {
    //   contentRenderer: 'sidebar-left',
    //   contentClass: 'chat-application',
    // },
    path: '/persidangan/monitoring',
    name: 'persidangan-monitoring',
    component: () => import('@/views/persidangan/monitoring/list/List.vue'),
  },
  // {
  //   path: '/tpl-monitoring/:laporan_id/:mode',
  //   name: 'tpl-monitoring-edit',
  //   component: () => import('@/views/tpl-monitoring/wizard/FormWizard.vue'),
  // },
  {
    path: '/persidangan/monitoring/:laporan_id/:mode',
    name: 'monitoring-persidangan-detail',
    component: () => import('@/views/persidangan/monitoring/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/panel/daftar-tunggu',
    name: 'panel-daftar-tunggu',
    component: () => import('@/views/persidangan/panel-daftar-tunggu/list/List.vue'),
  },
  {
    path: '/persidangan/panel/daftar-tunggu/:laporan_id/:redirect',
    name: 'panel-daftar-tunggu-edit',
    component: () => import('@/views/persidangan/monitoring/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/panel/jadwal',
    name: 'panel-jadwal',
    component: () => import('@/views/persidangan/panel-jadwal/list/List.vue'),
  },
  {
    path: '/persidangan/panel/jadwal/:id',
    name: 'panel-jadwal-edit',
    component: () => import('@/views/persidangan/panel-jadwal/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/pleno/jadwal',
    name: 'pleno-jadwal',
    component: () => import('@/views/persidangan/pleno-jadwal/list/List.vue'),
  },
  {
    path: '/persidangan/pleno/jadwal/:id',
    name: 'pleno-jadwal-edit',
    component: () => import('@/views/persidangan/pleno-jadwal/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/pleno/daftar-tunggu',
    name: 'pleno-daftar-tunggu',
    component: () => import('@/views/persidangan/pleno-daftar-tunggu/list/List.vue'),
  },
  {
    path: '/persidangan/pleno/daftar-tunggu/:laporan_id/:redirect',
    name: 'pleno-daftar-tunggu-edit',
    component: () => import('@/views/persidangan/monitoring/wizard/FormWizard.vue'),
  },
  // {
  //   path: '/persidangan/pleno/daftar-tunggu/:laporan_id',
  //   name: 'pleno-daftar-tunggu-edit',
  //   component: () => import('@/views/persidangan/pleno-daftar-tunggu/wizard/FormWizard.vue'),
  // },
  // {
  //   path: '/persidangan/pleno/jadwal',
  //   name: 'pleno-jadwal',
  //   component: () => import('@/views/persidangan/pleno-jadwal/list/List.vue'),
  // },
  // {
  //   path: '/persidangan/pleno/jadwal/:id',
  //   name: 'pleno-jadwal-edit',
  //   component: () => import('@/views/persidangan/pleno-jadwal/edit/Edit.vue'),
  // },
  {
    path: '/persidangan/pleno/putusan',
    name: 'putusan-pleno',
    component: () => import('@/views/persidangan/putusan-pleno/list/List.vue'),
  },
  {
    path: '/persidangan/pleno/putusan/create',
    name: 'putusan-pleno-create',
    component: () => import('@/views/persidangan/putusan-pleno/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/pleno/putusan/:laporan_id',
    name: 'putusan-pleno-detail',
    component: () => import('@/views/persidangan/putusan-pleno/wizard/FormWizard.vue'),
  },
  {
    path: '/persidangan/mkh',
    name: 'mkh',
    component: () => import('@/views/persidangan/mkh/list/List.vue'),
  },
  {
    path: '/persidangan/mkh/:laporan_id',
    name: 'mkh-edit',
    component: () => import('@/views/persidangan/mkh/wizard/FormWizard.vue'),
  },
  {
    path: '/status-pemantauan',
    name: 'status-pemantauan',
    component: () => import('@/views/status-pemantauan/list/List.vue'),
  },
  {
    path: '/status-pemantauan/:id',
    name: 'status-pemantauan-edit',
    component: () => import('@/views/status-pemantauan/edit/Edit.vue'),
  },
  {
    path: '/laporan-periodik',
    name: 'laporan-periodik',
    component: () => import('@/views/laporan-periodik/list/List.vue'),
  },
  {
    path: '/laporan-bulanan',
    name: 'laporan-bulanan',
    component: () => import('@/views/laporan-bulanan/list/List.vue'),
  },
  // {
  //   path: '/laporan-periodik/:uuid',
  //   name: 'laporan-periodik-edit',
  //   component: () => import('@/views/laporan-periodik/edit/Edit.vue'),
  // },
  {
    path: '/jenis-dokumen',
    name: 'jenis-dokumen',
    component: () => import('@/views/jenis-dokumen/list/List.vue'),
  },
  {
    path: '/jenis-dokumen/:id',
    name: 'jenis-dokumen-edit',
    component: () => import('@/views/jenis-dokumen/edit/Edit.vue'),
  },
  {
    path: '/jenis-dokumen-mkh',
    name: 'jenis-dokumen-mkh',
    component: () => import('@/views/jenis-dokumen-mkh/list/List.vue'),
  },
  {
    path: '/jenis-dokumen-mkh/:id',
    name: 'jenis-dokumen-mkh-edit',
    component: () => import('@/views/jenis-dokumen-mkh/edit/Edit.vue'),
  },
  {
    path: '/jenis-kelamin',
    name: 'jenis-kelamin',
    component: () => import('@/views/jenis-kelamin/list/List.vue'),
  },
  {
    path: '/jenis-kelamin/:id',
    name: 'jenis-kelamin-edit',
    component: () => import('@/views/jenis-kelamin/edit/Edit.vue'),
  },
  {
    path: '/jenis-instrumen',
    name: 'jenis-instrumen',
    component: () => import('@/views/jenis-instrumen/list/List.vue'),
  },
  {
    path: '/jenis-instrumen/:id',
    name: 'jenis-instrumen-edit',
    component: () => import('@/views/jenis-instrumen/edit/Edit.vue'),
  },
  {
    path: '/jenis-kelengkapan',
    name: 'jenis-kelengkapan',
    component: () => import('@/views/jenis-kelengkapan/list/List.vue'),
  },
  {
    path: '/jenis-kelengkapan/:id',
    name: 'jenis-kelengkapan-edit',
    component: () => import('@/views/jenis-kelengkapan/edit/Edit.vue'),
  },
  {
    path: '/jenis-laporan',
    name: 'jenis-laporan',
    component: () => import('@/views/jenis-laporan/list/List.vue'),
  },
  {
    path: '/jenis-laporan/:id',
    name: 'jenis-laporan-edit',
    component: () => import('@/views/jenis-laporan/edit/Edit.vue'),
  },
  {
    path: '/jenis-surat',
    name: 'jenis-surat',
    component: () => import('@/views/jenis-surat/list/List.vue'),
  },
  {
    path: '/jenis-surat/:id',
    name: 'jenis-surat-edit',
    component: () => import('@/views/jenis-surat/edit/Edit.vue'),
  },
  {
    path: '/jenis-pengadilan',
    name: 'jenis-pengadilan',
    component: () => import('@/views/jenis-pengadilan/list/List.vue'),
  },
  {
    path: '/jenis-pengadilan/:id',
    name: 'jenis-pengadilan-edit',
    component: () => import('@/views/jenis-pengadilan/edit/Edit.vue'),
  },
  {
    path: '/jenis-tindakan-pemantauan',
    name: 'jenis-tindakan-pemantauan',
    component: () => import('@/views/jenis-tindakan-pemantauan/list/List.vue'),
  },
  {
    path: '/jenis-tindakan-pemantauan/:id',
    name: 'jenis-tindakan-pemantauan-edit',
    component: () => import('@/views/jenis-tindakan-pemantauan/edit/Edit.vue'),
  },
  {
    path: '/daftar-tim',
    name: 'daftar-tim',
    component: () => import('@/views/daftar-tim/list/List.vue'),
  },
  {
    path: '/daftar-tim/:id',
    name: 'daftar-tim-edit',
    component: () => import('@/views/daftar-tim/edit/Edit.vue'),
  },
  {
    path: '/tim-user',
    name: 'tim-user',
    component: () => import('@/views/tim/tim-user/list/List.vue'),
  },
  {
    path: '/tim-user/:id',
    name: 'tim-user-edit',
    component: () => import('@/views/tim/tim-user/edit/Edit.vue'),
  },
  {
    path: '/badan-peradilan',
    name: 'badan-peradilan',
    component: () => import('@/views/badan-peradilan/list/List.vue'),
  },
  {
    path: '/badan-peradilan/:id',
    name: 'badan-peradilan-edit',
    component: () => import('@/views/badan-peradilan/edit/Edit.vue'),
  },
  // Start Kerjasama
  // {
  //   path: '/kerjasama',
  //   name: 'kerjasama',
  //   component: () => import('@/views/laporan/list/List.vue'),
  // },
  // End Kerjasama

  // Start Kerjasama Baru
  // {
  //   path: '/kerjasama-baru',
  //   name: 'kerjasama-baru',
  //   component: () => import('@/views/kerjasama-baru/wizard/FormWizard.vue'),
  // },
  // {
  //   path: '/kerjasama-baru/:id',
  //   name: 'kerjasama-baru-edit',
  //   component: () => import('@/views/kerjasama-baru/wizard/FormWizard.vue'),
  // },

  // {
  //   path: '/kerjasama/:readonly/:id',
  //   name: 'kerjasama-readonly',
  //   component: () => import('@/views/kerjasama-baru/wizard/FormWizard.vue'),
  // },
  // End Kerjasama Baru

  {
    path: '/instansi',
    name: 'instansi',
    component: () => import('@/views/instansi/list/List.vue'),
  },
  {
    path: '/instansi/:id',
    name: 'instansi-edit',
    component: () => import('@/views/instansi/edit/Edit.vue'),
  },
 {
    path: '/pendaftar',
    name: 'pendaftar',
    component: () => import('@/views/pendaftar/list/List.vue'),
  },
  {
    path: '/pendaftar/:id',
    name: 'pendaftar-edit',
    component: () => import('@/views/pendaftar/edit/Edit.vue'),
  },
 {
    path: '/penghubung',
    name: 'penghubung',
    component: () => import('@/views/penghubung/list/List.vue'),
  },
  {
    path: '/penghubung/:id',
    name: 'penghubung-edit',
    component: () => import('@/views/penghubung/edit/Edit.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
