import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import {
  checkExpired,
} from '@/api/user'

import { AuthLogout } from '@/connection-api/authentication'
import { initialAbility } from '@/libs/acl/config'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  async created() {
    const response = await checkExpired()
    if (response.data.code === 1) {
      //Do somthing that important if token verified
    } else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      AuthLogout()
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
    }
  },
  render: h => h(App),
}).$mount('#app')
