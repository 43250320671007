import axios from 'axios'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL
// 'http://localhost/ditjenpas/simba/api/api/v1'

/*
 * Returns default headers list
 * which will be used with every request.
 */
function getHeaders(multipart = false) {
  let defaultHeaders = DEFAULT_HEADERS

  if (multipart) {
    defaultHeaders = {}
  }
  const token = localStorage.getItem('accessToken')
  if (token) {
    defaultHeaders = {
      Authorization: `bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      // 'Content-type': 'application/json',
      // 'Content-Type': 'multipart/form-data',
      ...defaultHeaders,
    }
  }
  return defaultHeaders
}

// Just a convenient shorthand
export const esc = encodeURIComponent

// Returns formatted query string from object
// export function qs(params) {
//   return (
//     Object
//       .keys(params)
//       // .map(k => esc(k) + '=' + esc(params[k]))
//       .map(k => esc(k) + '=' + esc(params[k]))
//       .join('&')
//   )
// }

/*
 * Wraps axios and provides
 * more convenient get method
 * calls with data.
 */
// export function get(uri, data = {}) {
export function get(uri, data) {
  // if (Object.keys(data).length > 0) {
  //   uri = `${uri}?${qs(data)}`
  // }
  return axios.get(uri, {
    params: data,
    headers: getHeaders(),
    // withCredentials: true
  })
}

export function get2(uri, data) {
  // if (Object.keys(data).length > 0) {
  //   uri = `${uri}?${qs(data)}`
  // }
  return axios.get(uri, {
    params: data,
    headers: getHeaders(),
    responseType: "arraybuffer",
    // withCredentials: true
  })
}

// export function hapus(uri) {
//   return axios.delete(uri)
// }

export function hapus(uri) {
  return axios.delete(uri, {
    headers: getHeaders(),
  })
}

// export function hapus(uri, data) {
//   return axios.delete(uri, data)
// }

export function post(uri, data) {
  return axios.post(uri, data, {
    headers: getHeaders(),
    // withCredentials: true
  })
  // .then(response => (console.log(response.data)))
  // .catch(error => console.log(error))
}

export function postNoHeader(uri, data) {
  return axios.post(uri, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    // withCredentials: true
  })
}

export function update(uri, data) {
  return axios.patch(uri, data, {
    headers: getHeaders(),
    // withCredentials: true
  })
}

export function upload(uri, file, metadata, onUploadProgress) {
  const formData = new FormData()
  formData.append('File', file)
  // formData.append('dok_id', metadata.dok_id)
  // formData.append('tanggal', metadata.tanggal)
  if (metadata.retribusi_id) {
    formData.append('retribusi_id', metadata.retribusi_id)
  }
  if (metadata.asset_id) {
    formData.append('asset_id', metadata.asset_id)
  }
  if (metadata.permohonan_id) {
    formData.append('permohonan_id', metadata.permohonan_id)
  }
  if (metadata.permohonan_asset_id) {
    formData.append('permohonan_asset_id', metadata.permohonan_asset_id)
  }
  if (metadata.pradesain_id) {
    formData.append('pradesain_id', metadata.pradesain_id)
  }
  if (metadata.pelaksanaan_id) {
    formData.append('pelaksanaan_id', metadata.pelaksanaan_id)
  }
  if (metadata.pradesain_id) {
    formData.append('pradesain_id', metadata.pradesain_id)
  }
  if (metadata.uraian_pekerjaan_id) {
    formData.append('uraian_pekerjaan_id', metadata.uraian_pekerjaan_id)
  }
  // formData.append('jenis_dokumen_id', metadata.jenis_dokumen_id)
  // formData.append('verification_status', 0)
  // formData.append('token', 1)
  const token = localStorage.getItem('accessToken')
  return axios.post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
    },
    onUploadProgress,
  })
}

export function image(uri) {
  return axios.get(uri, {
    headers: getHeaders(),
    responseType: 'blob',
    // withCredentials: true
  })
}

export function download(uri) {
  return axios.get(uri, {
    headers: getHeaders(),
    responseType: 'blob',
    // withCredentials: true
  })
}
