import {
  get, post, hapus, update, upload, download,
} from './helpers'

function getAllUser() {
  return get('/user/list')
}

function getUser(UserId) {
  return get(`/user/${UserId}`)
}

function getUserData() {
  return get(`/pengguna-nonadmin`)
}

function insUser(data) {
  return post('/user', data)
}

function ubahUser($data) {
  return update('/user', $data)
}

function uploadFileUser(file, metadata, onUploadProgress) {
  return upload('/user/uploadfile', file, metadata, onUploadProgress)
}

function changePassword($data) {
  return update('/change-password', $data)
}

function forgotPassword($data) {
  return post('/lupa-password', $data)
}

function resetPassword($data) {
  return post('/reset-password', $data)
}
function resetPasswordNew($data) {
  return post('/reset-password-new', $data)
}

function deleteHakAkses($data) {
  return post('/hak/delete', $data)
}

function updateUser($data) {
  return post(`update/user`, $data)
}

export function downloadAvatar(filename) {
  return download(`/user/download/${filename}`)
}

function checkExpired($data) {
  return get('/check/token', $data)
}

/* eslint-disable import/prefer-default-export */
export {
  getAllUser,
  getUser,
  insUser,
  ubahUser,
  uploadFileUser,
  changePassword,
  forgotPassword,
  resetPassword,
  deleteHakAkses,
  updateUser,
  getUserData,
  checkExpired,
  resetPasswordNew,
}
