import {
  get,
  post,
} from './helpers'

export function onAccessTokenFetched(accessToken) {
  this.subscribers = this.subscribers.filter(callback => callback(accessToken))
}

export function addSubscriber(callback) {
  this.subscribers.push(callback)
}

export function getUserData() {
  return localStorage.getItem('userData')
}

export function getAbility(subject, action) {
  const userData = JSON.parse(localStorage.getItem('userData'))
  for (let i = 0; i < userData.ability.length; i += 1) {
    if (userData.ability[i].subject === subject && userData.ability[i].action === action) {
      return true
    }
  }
  return false
}

export function getToken() {
  return localStorage.getItem('accessToken')
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

export function setToken(value) {
  localStorage.setItem('accessToken', value)
}

export function setRefreshToken(value) {
  localStorage.setItem('refreshToken', value)
}

export function verifToken($data) {
  return post('/user/verify', $data)
}

export function Authlogin(...args) {
  return post('/account/login', ...args)
}

export function AuthLogout() {
  return get('/logout')
}

export function Authregister(...args) {
  return post('/account/register', ...args)
}

export function VerifyEmail(...args) {
  return post('/account/verify', ...args)
}

export function refreshToken() {
  return post('/refresh-token', {
    refreshToken: this.getRefreshToken(),
  })
}
